/* @import url("https://fonts.googleapis.com/css?family=EB+Garamond");
@import url("https://fonts.googleapis.com/css?family=Caveat"); */

@font-face {
	font-family: "George Regular";
	font-style: normal;
	font-weight: normal;
	src: local("George Regular"),
		url("../public/fonts/GeorgeRegular.woff") format("woff");
}

@font-face {
	font-family: "George Italic";
	font-style: normal;
	font-weight: normal;
	src: local("George Italic"),
		url("../public/fonts/GeorgeItalic.woff") format("woff");
}

@font-face {
	font-family: "George SemiBold";
	font-style: normal;
	font-weight: normal;
	src: local("George SemiBold"),
		url("../public/fonts/GeorgeSemiBold.woff") format("woff");
}

@font-face {
	font-family: "George SemiBold Italic";
	font-style: normal;
	font-weight: normal;
	src: local("George SemiBold Italic"),
		url("../public/fonts/GeorgeSemiBoldItalic.woff") format("woff");
}

@font-face {
	font-family: "George Bold";
	font-style: normal;
	font-weight: normal;
	src: local("George Bold"),
		url("../public/fonts/GeorgeBold.woff") format("woff");
}

@font-face {
	font-family: "George Bold Italic";
	font-style: normal;
	font-weight: normal;
	src: local("George Bold Italic"),
		url("../public/fonts/GeorgeBoldItalic.woff") format("woff");
}

@font-face {
	font-family: "Savoye LET Plain";
	font-style: normal;
	font-weight: normal;
	src: local("Savoye LET Plain"),
		url("../public/fonts/savoyeletplain.ttf") format("truetype");
}

body,
html,
#root {
	height: 100%;
	margin: 0;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace, "EB Garamond";
}
